<template>
	<div>
		<!-- 导航栏 -->
		<van-nav-bar
		class="nav_bar_color"
		title="邀请函模板列表"
		left-text="返回"
		left-arrow
		@click-left="onClickLeft"
		fixed
		z-index="11" 
		:border="false"
		placeholder
		>
	</van-nav-bar>

		<div class="invitation-menus">
			<div class="invitation-menu " :class="[type=='invitation_letter' ? 'invitation-menu__choose' : '']"
				v-on:click="enterInvitationList('invitation_letter')">
				<img class="invitation-menu__logo" src="@/assets/invitation/invitation_letter.png"
					mode="widthFix">
				<div class="invitation-menu__name">邀请函</div>
			</div>
			<!-- :class={'invitation-menu__choose':type=='thank_letter'?true:false}  -->
			<div class="invitation-menu" :class="[type=='thank_letter' ? 'invitation-menu__choose' : '']"
				v-on:click="enterInvitationList('thank_letter')">
				<img class="invitation-menu__logo" src="@/assets/invitation/thank_letter.png"
					mode="widthFix">
				<div class="invitation-menu__name">致谢函</div>
			</div>
		</div>

		<div class="center_middles" v-if="type=='invitation_letter'" v-for="v,index in invitationLetterList"
			:key="index" v-on:click="enterInvitation(v)">
			<div class="center_middles_nav">
				<div class="center_middles_setup">
					<div>
						<img class="invitation-menu__logo" src="@/assets/invitation/invitation_letter.png"
							mode="widthFix">
					</div>
					<div class="invitation-menu__title">
						{{v.name}}
					</div>
				</div>
				<div class="center_middles_list">
					<div class="center_middles_content">
						<span>{{v.note}}</span>
					</div>
				</div>
			</div>
		</div>

		<div class="center_middles" v-if="type=='thank_letter'" v-for="v,index2 in thankLetterList"
			:key="index2" v-on:click="enterInvitation(v)">
			<div class="center_middles_nav">
				<div class="center_middles_setup">
					<div>
						<img class="invitation-menu__logo" src="@/assets/invitation/thank_letter.png"
							mode="widthFix">
					</div>
					<div class="invitation-menu__title">
						{{v.name}}
					</div>
				</div>
				<div class="center_middles_list">
					<div class="center_middles_content">
						<span>{{v.note}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				type: 'invitation_letter',
				pageTitle: '选择邀请函模版',
				invitationLetterList: [],
				thankLetterList: [],
				scrollTop: 0,
			};
		},
		mounted() {
			const type = localStorage.getItem('Invitationtype')
			if(type){
				this.enterInvitationList(type)
			}else{
				if (this.$route.query?.type) {
					this.enterInvitationList(this.$route.query.type)
				} else {
					this.enterInvitationList('invitation_letter')
				}
			}
			

		},
		methods: {
			enterInvitationList(type) {
				this.type = type;
				this.getInvitationList();
			},
			enterInvitation(v) {
				localStorage.setItem('Invitationtype',this.type)
				if (v.id > 0 &&  v.id != 8) {
					this.$router.push({
						path: '/invitation/letter-info?id='+v.id
					})
				} else if(v.id == -1) {
					this.$router.push({
						path: '/invitation/test-drive'
					})
				}else if(v.id == 8) {
					this.$router.push({
						path: '/invitation/enjoy-info'
					})
				}
			},
			async getInvitationList() {
				const {
					data: res
				} = await this.$http.post('/api/scsj/get_invitation_formwork_list_1_7')
				if (res.code == 1) {
					res.data.res.forEach((v) => {
						if (v.scsj_invitation_type_id == 4) {
							this.invitationLetterList = v.res;
						}
						if (v.scsj_invitation_type_id == 5) {
							this.thankLetterList = v.res;
						}
					})
				}
			},
		}
	}
</script>

<style lang="scss">
	.invitation-menus {
		padding: 40px 80px 0;
		width: 100%;
		box-sizing: border-box;
		display: flex;
		// flex-wrap: wrap;
		justify-content: space-around;
	}

	.invitation-menu {
		// width: 49%;
		border: 2px solid #303030;
		border-radius: 8px;
		text-align: center;
		width: 200px;
		height: 180px;
		padding-top: 40px;
		margin-bottom: 20px;
		background: #303030;
		box-sizing: content-box;
		-moz-box-sizing: content-box;
		/* Firefox */
		-webkit-box-sizing: content-box;

		/* Safari */
		// margin-right: 90px;
		// margin-bottom: 90px;
		&__logo {
			width: 80px;
			height: 80px;
		}

		&__name {
			line-height: 40px;
			font-size: 32px;
			font-family: HYQiHeiY3-45;
			color: #FFFFFF;
			font-weight: normal;
		}

		&__title {
			padding-left: 20px;
			font-size: 36px;
			font-family: HYQiHeiY3-55;
			font-weight: normal;
			color: #FFFFFF;
			line-height: 42px;
		}

		&__choose {
			border: 2px solid #FA0037;
		}
	}

	.center_middles {
		padding: 0 30px 0;
		margin-top: 24px;
		z-index: 2;

		.center_middles_nav {
			padding: 36px;
			background: linear-gradient(135deg, #29282B 0%, #29282B 100%);
			border-radius: 12px;
			// height: 200px;
			color: #ffffff;

			.center_middles_setup {
				font-weight: normal;
				font-family: HYQiHeiY3-55;
				font-size: 36px;
				display: flex;
				justify-content: flex-start;
				img{
					height: 72px;
					width: 72px;
				}
			}

			.center_middles_list {
				display: flex;

				.center_middles_content {
					flex-flow: column;
					align-items: center;
					margin-top: 10px;

					span {
						font-size: 32px;
						font-family: HYQiHeiY3-45;
						font-weight: normal;
						color: #FFFFFF;
						line-height: 40px;

					}
				}
			}
		}
	}
</style>
